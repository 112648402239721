
import React from 'react';
import { User } from '../../services/api/auth';

import { useState, useMemo, useLayoutEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import authSessionContext from './context';
import * as authStore from '../../services/storage/auth';
// import { login } from '../../services/api/auth';

export type AuthSessionProviderProps = {
  children?: React.ReactNode;
};

const loginUserMutationDocument = gql`
mutation UserLogin ($email: String!, $password: String!) {
  userLogin (email: $email, password: $password) {
    credentials {
      accessToken
      expiry
      uid
    }
  }
}
`;

const AuthSessionProvider:React.FC<AuthSessionProviderProps> = (props) => {
  const [user, setUser] = useState<User|null>(null);
  const [initializing, setInitializing] = useState(true);

  const [loginUser] = useMutation(loginUserMutationDocument);

  /**
   * handleLoginUser()
   */
  const handleLoginUser = useCallback(async (email:string, password:string) => {
    const {
      data: {
        userLogin: {
          credentials: {
            accessToken,
            expiry,
            uid
          }
        }
      }
    } = await loginUser({ variables: { email, password } });

    await Promise.all([
      authStore.setAccessToken(accessToken),
      authStore.setTokenExpiry(expiry*1000),
      authStore.setUser({ email: uid })
    ]);

    setUser({ email: uid, id: uid })
  }, [loginUser]);

  /**
   * logoutUser()
   */
  const logoutUser = useCallback(async () => {
    await authStore.clear();
    setUser(null);
  }, []);

  /**
   * authSession
   */
  const authSession = useMemo(() => ({
    user,
    loginUser: handleLoginUser,
    logoutUser
  }), [user, handleLoginUser, logoutUser]);

  // Retrieve authentication state.
  useLayoutEffect(() => {
    (async () => {
      try {
        setUser(await authStore.getUser())
      }
      catch (err) {
        console.error(err);
      }
      finally {
        setInitializing(false);
      }
    })();
  }, []);

  return (
    <authSessionContext.Provider value={authSession}>
      {!initializing && props.children}
    </authSessionContext.Provider>
  );
};

export default AuthSessionProvider;
