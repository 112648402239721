
import type { FC } from 'react';
import type { OptionType, IDType } from '../@lib/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OptionsAccordion from './OptionsAccordion';
import TimeInputAccordion from './TimeInputAccordion';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as ThemeIcon} from '../@lib/icons/theme-icon.svg';
import {ReactComponent as CategoryIcon} from '../@lib/icons/category-icon.svg';
import {ReactComponent as IndicatorIcon} from '../@lib/icons/indicator-icon.svg';
import {ReactComponent as CountryIcon} from '../@lib/icons/country-icon.svg';
import {ReactComponent as TimeIcon} from '../@lib/icons/time-icon.svg';

import { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery, useLazyQuery, gql } from '@apollo/client';

export type IndicatorOptions = {
  themeOptions: OptionType<IDType>[];
  categoryOptions: OptionType<IDType>[];
  countryOptions: OptionType<IDType>[];
};

export type SelectedOptions = {
  theme?: OptionType<IDType>;
  category?: OptionType<IDType>;
  indicatorIds?: IDType[];
  countries?: OptionType<IDType>[];
  yearsRange?: {
    startYear: number;
    endYear: number;
  };
};

export type DataFiltersProps = {
  onUpdate: (opts:SelectedOptions)=>void;
};

const today = new Date();

const AlignedRow = styled(Box)(({theme}) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center'
}));

const optionsQueryDocument = gql`
query Options {
  themeOptions: themes {
    label: title
    value: id
  }
  categoryOptions: indicatorCategories {
    label: name
    value: id
  }
  countryOptions: countries {
    label: name
    value: id
  }
}
`;

const indicatorOptionsQueryDocument = gql`
query IndicatorOptions ($themeId: Int!, $indicatorCategoryId: Int!) {
  indicatorOptions: indicators(themeId: $themeId, indicatorCategoryId: $indicatorCategoryId) {
    label
    value: id
  }
}
`;

const DataFilters:FC<DataFiltersProps> = (props) => {
  const { onUpdate } = props;

  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedIndicators, setSelectedIndicators] = useState<Set<IDType>|null>(new Set<IDType>());
  const [yearsRange, setYearRange] = useState({
    startYear: today.getFullYear() - 5,
    endYear: today.getFullYear()
  });

  const {data, loading} = useQuery<IndicatorOptions>(optionsQueryDocument);
  const [getIndicators, {
    data:indicators,
    loading: loadingIndicatorOptions
  }] = useLazyQuery<{indicatorOptions: OptionType<IDType>[]}>(indicatorOptionsQueryDocument);

  const themeOption = useMemo(() => {
    return data?.themeOptions.find((op) => op.value === selectedTheme)
  }, [data, selectedTheme]);

  const categoryOption = useMemo(() => {
    return data?.categoryOptions.find((op) => op.value === selectedCategory)
  }, [data, selectedCategory]);

  const countryOption = useMemo(() => {
    return data?.countryOptions.find((op) => op.value === selectedCountry);
  }, [data, selectedCountry]);

  useEffect(() => {
    onUpdate({
      theme: themeOption,
      category: categoryOption,
      indicatorIds: selectedIndicators ? Array.from(selectedIndicators) : undefined,
      countries: countryOption ? [countryOption] : undefined,
      yearsRange
    });
  }, [themeOption, categoryOption, selectedIndicators, countryOption, yearsRange, onUpdate]);

  useEffect(() => {
    if (selectedTheme && selectedCategory) {
      getIndicators({
        variables: {
          themeId: Number(selectedTheme),
          indicatorCategoryId: Number(selectedCategory)
        }
      });
    }
  }, [getIndicators, selectedTheme, selectedCategory]);

  return (
    <Box>
      <OptionsAccordion
        defaultExpanded
        value={selectedTheme}
        onChange={(v)=>{
          setSelectedIndicators(null);
          setSelectedTheme(v);
        }}
        options={data?.themeOptions || []}
        loading={loading}
        title={(
          <AlignedRow>
            <SvgIcon component={ThemeIcon} inheritViewBox fontSize="small" />
            <Typography>Theme</Typography>
          </AlignedRow>
        )}
        disableGutters
        square
      />
      <OptionsAccordion
        value={selectedCategory}
        onChange={(v)=>{
          setSelectedIndicators(null);
          setSelectedCategory(v);
        }}
        options={data?.categoryOptions || []}
        loading={loading}
        title={(
          <AlignedRow>
            <SvgIcon component={CategoryIcon} inheritViewBox fontSize="small" />
            <Typography>Categories</Typography>
          </AlignedRow>
        )}
        disableGutters
        square
      />
      <OptionsAccordion
        multiple
        values={selectedIndicators}
        onMultipleSelect={(v)=>setSelectedIndicators(v)}
        options={indicators?.indicatorOptions || []}
        loading={loadingIndicatorOptions}
        disabled={!selectedCategory || !selectedTheme}
        title={(
          <AlignedRow>
            <SvgIcon component={IndicatorIcon} inheritViewBox fontSize="small" />
            <Typography>Indicators</Typography>
          </AlignedRow>
        )}
        disableGutters
        square
      />
      <OptionsAccordion
        value={selectedCountry}
        onChange={(v)=>setSelectedCountry(v)}
        options={data?.countryOptions || []}
        loading={loading}
        disabled={!selectedCategory || !selectedTheme}
        title={(
          <AlignedRow>
            <SvgIcon component={CountryIcon} inheritViewBox fontSize="small" />
            <Typography>Country</Typography>
          </AlignedRow>
        )}
        disableGutters
        square
      />
      <TimeInputAccordion
        value={yearsRange}
        onChange={(v)=>setYearRange(v)}
        title={(
          <AlignedRow>
            <SvgIcon component={TimeIcon} inheritViewBox fontSize="small" />
            <Typography>Time</Typography>
          </AlignedRow>
        )}
        disabled={!selectedCategory || !selectedTheme}
        disableGutters
        square
      />
    </Box>
  );
};

export default DataFilters;
