
import React from 'react';
import type { Person } from '../../types';
import Card from '@mui/material/Card';
import CoverImage from '../@common/CoverImage';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StatusTag from '../@common/StatusTag';
import placeholderImage from '../../images/person-placeholder.jpg';

import { styled } from '@mui/material/styles';

export type PersonCardProps = {
  person?: Pick<Person, 'firstname'|'lastname'|'picture'>;
};

const CardMedia = styled(CoverImage)({
  position: 'relative',
  '&:before': {
    content: `""`,
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: 'linear-gradient(to top, #ffffff, transparent 40%)'
  }
});

const DetailsWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: '#494e5499',
  color: '#ffffff',
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2)
}));

const StatusWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#ffffff',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[3]
}));

const PersonCard:React.FC<PersonCardProps> = (props) => {
  const {
    person
  } = props;

  return (
    <Card sx={{ position: 'relative' }}>
      <CardMedia
        height={440}
        image={person?.picture || placeholderImage}
      />
      <CardContent>
        <DetailsWrapper>
          <Typography variant="h5" mb={1} sx={{ textTransform: 'capitalize' }}>{person?.firstname} {person?.lastname}</Typography>
          <Typography variant="subtitle2">Country</Typography>
          <Typography component="p" variant="caption">Person details will go here</Typography>
        </DetailsWrapper>
      </CardContent>

      <StatusWrapper>
        <StatusTag label="Approved" status="approved" />
      </StatusWrapper>
    </Card>
  );
};

export default PersonCard;
