
import React from 'react';
import type { Person } from '../../@lib/types';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLabel from '../../@lib/components/@common/TabLabel';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import CTAContainer from '../../@lib/components/@common/CTAContainer';
import SearchBar from '../../@lib/components/@common/SearchBar';
import placeholderImage from '../../@lib/images/person-placeholder.jpg';

import { useAuthSession } from '../../@lib/components/AuthSessionProvider';
import { useQuery, gql } from '@apollo/client';

export type PersonDetails = Pick<Person, 'id'|'firstname'|'lastname'|'title'|'picture'>;

const rowSkeleton = <Skeleton variant="rounded" height={150} />;

const peopleQueryDocument = gql`
query People {
  people {
    id
    firstname
    lastname
    title
    picture
  }
}
`;

const PeopleList:React.FC = () => {
  const {user} = useAuthSession();
  const isLoggedIn = !!user;

  const { data, loading } = useQuery<{people: PersonDetails[]}>(peopleQueryDocument);

  return (
    <div>
      <AppBar>
        <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="space-between">
          <PageTitle
            title="People"
            subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
        {loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />}
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 1, my: 2, display: 'flex', justifyContent: 'center' }}>
          <Tabs value={0}>
            <Tab label={<TabLabel count={90}>All</TabLabel>} />
            <Tab label={<TabLabel count={50}>Approved</TabLabel>} />
            <Tab label={<TabLabel count={25}>Pending</TabLabel>} />
            <Tab label={<TabLabel count={15}>Rejected</TabLabel>} />
          </Tabs>
        </Paper>

        <Stack gap={2}>
          {loading && (!data?.people || data?.people.length === 0) && [1,2,3].map((_, i) => (
            <React.Fragment key={`${i}`}>{rowSkeleton}</React.Fragment>
          ))}

          {data?.people.map((person, i) => (
            <Card key={`${i}-${person.id}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CardMedia
                component="img"
                sx={{ flexBasis: 150, maxWidth: 150, p: 2, borderRadius: 4 }}
                height={150}
                image={person.picture || placeholderImage}
                alt={`${person.firstname}'s picture`}
              />
              <CardContent component={Stack} sx={{ flexGrow: 1 }} justifyContent="space-between" alignItems="flex-start" gap={2}>
                <div>
                  <Typography variant="h6">{person.firstname} {person.lastname}</Typography>
                  <Typography variant="body2">Country</Typography>
                  <Typography component="p" variant="caption">
                    {person.title}
                  </Typography>
                </div>

                <Chip label="Approved" size="small" />
              </CardContent>
              {isLoggedIn && (
                <Stack flexBasis="20%" gap={2}>
                  <Button size="small" variant="contained">EDIT</Button>
                  <Button size="small" variant="contained">DELETE</Button>
                </Stack>
              )}
            </Card>
          ))}
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="textSecondary" underline="none">Show more</Link>
        </Paper>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" mb={2}>Add new people to the platform</Typography>
            <Button
              component={RouterLink}
              to="/people/new"
              sx={{
                px: 6,
                backgroundColor: '#ffffff',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >Add People</Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
};

export default PeopleList;
