
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const ButtonTabs = styled(MuiTabs)(({theme}) => ({
  minHeight: 40,
  borderRadius: theme.shape.borderRadius,
  border: 0,
  backgroundColor: theme.palette.grey['200']
}));

export const ButtonTab = styled(MuiTab)(({theme}) => ({
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  minHeight: 40,
  border: 0,
  borderRadius: theme.shape.borderRadius,
  fontSize: 16,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    boxShadow: theme.shadows[2]
  }
}));

export default ButtonTabs;
