
import React from 'react';
import type { PersonInputData } from '../../@lib/types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AppBar from '../../@lib/components/@common/AppBar';
import PageTitle from '../../@lib/components/@common/PageTitle';
import SearchBar from '../../@lib/components/@common/SearchBar';
import PersonEditor from '../../@lib/components/PersonEditor';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../@lib/components/@common/NotificationsProvider';
import { useMutation, gql } from '@apollo/client';

const personCreateMutationDocument = gql`
mutation PersonCreate ($personInput: PersonCreateInput!) {
  personCreate (input: $personInput) {
    person {
      id
    }
  }
}
`;

const PersonNew:React.FC = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const [createPerson, { loading }] = useMutation(personCreateMutationDocument);

  const handleSave = useCallback(async (data:PersonInputData) => {
    try {
      await createPerson({
        variables: {
          personInput: {
            personInput: data
          }
        }
      });

      notify.success('Person created successfuly!');
      navigate('/people');
    }
    catch (err) {
      notify.error(err as Error);
    }
  }, [createPerson, navigate, notify]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack direction="row" flexGrow={1} gap={2} justifyContent="space-between" alignItems="center">
          <PageTitle
            title="Add new people"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>
      <Container sx={{ my: 3 }}>
        <PersonEditor
          disabled={loading}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </Container>
    </Box>
  );
};

export default PersonNew;
