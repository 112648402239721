
import type { FC } from 'react';
import type { IndicatorValue } from '../@lib/types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ButtonTabs, { ButtonTab } from '../@lib/components/@common/ButtonTabs';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '../@lib/components/@common/TableHead';
import TableBody from '../@lib/components/@common/TableBody';
import TableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import AppBar from '../@lib/components/@common/AppBar';
import PageTitle from '../@lib/components/@common/PageTitle';
import SearchBar from '../@lib/components/@common/SearchBar';
import * as HighCharts from 'highcharts';
import HighChartsReact from 'highcharts-react-official';
import DataFilters, { SelectedOptions } from './DataFilters';
import {ReactComponent as DataPlaceholderIcon} from '../@lib/icons/data-illustration-icon.svg';
import {ReactComponent as TableIcon} from '../@lib/icons/table-icon.svg';
import {ReactComponent as ChartIcon} from '../@lib/icons/chart-icon.svg';

import { useState, useCallback, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, gql } from '@apollo/client';
import { useNotify } from '../@lib/components/@common/NotificationsProvider';

const TableCell = styled(MuiTableCell)({
  '&:nth-of-type(even)': {
    '&.MuiTableCell-head:before': {
      backgroundColor: 'rgba(94, 113, 176, 0.1)'
    },
    '&.MuiTableCell-body': {
      backgroundColor: 'rgba(94, 113, 176, 0.1)'
    }
  }
});

const indicatorValueQueryDocument = gql`
query IndicatorValue ($indicatorIds: [ID!]!, $countryIds: [ID!]!, $startYear: Int!, $endYear: Int!) {
  indicatorValues (indicatorIds:$indicatorIds, countryIds:$countryIds, startYear:$startYear, endYear:$endYear) {
    id
    year
    value
    indicator {
      id
      label
      ratingType
    }
  }
}
`;

const Indicators:FC = () => {
  const notify = useNotify();
  const [currTab, setCurrTab] = useState('table');
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions|null>(null);
  const [getIndicatorValues, {data, loading}] = useLazyQuery<{indicatorValues: IndicatorValue[]}>(indicatorValueQueryDocument);

  const yearsList = useMemo(() => {
    if (!selectedOptions?.yearsRange)
      return [];

    const startYear = Math.min(selectedOptions.yearsRange.startYear, selectedOptions.yearsRange.endYear);
    const endYear = Math.max(selectedOptions.yearsRange.startYear, selectedOptions.yearsRange.endYear, 0);
    return Array(endYear - startYear + 1).fill(null).map((_, i)=>startYear+i);
  }, [selectedOptions]);

  const parsedData = useMemo(() => {
    if (!data)
      return data;

    const indicatorsMap = {} as {[key:string]: {type:string; label:string; data:Record<string|number, any>}};

    data.indicatorValues.forEach((v) => {
      const map = indicatorsMap[v.indicator.id] || {type: v.indicator.ratingType, label: v.indicator.label, data: {}};
      map.data[v.year] = v.value;
      indicatorsMap[v.indicator.id] = map;
    });

    return Object.values(indicatorsMap);
  }, [data]);

  /**
   * BEGIN: Chart options
   */
  const chartOptions = useMemo(() => {
    if (!parsedData)
      return undefined;

    return {
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        labels: {
          enabled: false
        },
        title: {
          enabled: false
        }
      },
      series: (() => {
        let tempSeries = [];
        for(let data of parsedData) {
          if (data.type !== 'percentage')
            continue;

          tempSeries.push({
            // color: '#F9B500',
            borderRadius: 8,
            pointWidth: 32,
            name: data.label,
            label: {
              enabled: false
            },
            data: Object.keys(data.data).sort().map((k)=>({ y: Number(data.data[k]) || 0, name: k }))
          });
        }
        return tempSeries;
      })()
    };
  }, [parsedData]);
  /**
   * END: Chart options
   */

  const handleFiltersUpdate = useCallback((opts:SelectedOptions) => {
    setSelectedOptions(opts);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (
          !selectedOptions ||
          !selectedOptions.countries ||
          !selectedOptions.indicatorIds ||
          !selectedOptions.yearsRange?.startYear ||
          !selectedOptions.yearsRange.endYear
        )
          return;
  
        await getIndicatorValues({
          variables: {
            indicatorIds: selectedOptions.indicatorIds,
            countryIds: selectedOptions.countries.map((o)=>o.value),
            startYear: selectedOptions.yearsRange.startYear,
            endYear: selectedOptions.yearsRange.endYear
          }
        });
      }
      catch (err) {
        notify.error(err as Error)
      }
    })();
  }, [getIndicatorValues, notify, selectedOptions]);

  const hasData = parsedData && parsedData.length > 0;

  return (
    <Box pb={3}>
      <AppBar>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
          <PageTitle
            title="Indicators"
            subtitle="NGO Details will go here..."
          />
          <SearchBar
            placeholder="Search tree and keyword"
          />
        </Stack>
      </AppBar>

      <Container sx={{ py: 3 }}>
        <Stack direction={{ lg: 'row' }} gap={3}>
          <Paper sx={{flexBasis: { lg: '40%', xl: '30%' }, py: 2, alignItems: {lg: 'flex-start'}, minWidth: 0}}>
            <Container sx={{ pt: 2 }}>
              <Typography variant="h4" mb={3} lineHeight={1} noWrap>Please select data</Typography>
              <Divider sx={{ my: 1 }}/>
            </Container>

            <DataFilters onUpdate={handleFiltersUpdate} />
          </Paper>

          <Paper component={Container} sx={{flexGrow: 1, py: 2, minWidth: 0}}>
            <Stack direction={{ md: 'row' }} gap={1} justifyContent={{ md: 'space-between' }}>
              <ButtonTabs value={currTab} onChange={(ev, v)=>setCurrTab(v)}>
                <ButtonTab
                  label="Table"
                  value="table"
                  icon={<SvgIcon component={TableIcon} inheritViewBox fontSize="small" />}
                  iconPosition="start"
                />
                <ButtonTab
                  label="Chart"
                  value="chart"
                  icon={<SvgIcon component={ChartIcon} inheritViewBox fontSize="small" />}
                  iconPosition="start"
                />
              </ButtonTabs>

              <Button color="secondary" variant="contained">Download Options</Button>
            </Stack>

            <Divider sx={{ my: 2 }} />

            <Stack direction={{md: 'row'}} gap={3} flexWrap="nowrap">
              <div>
                <Typography component="span" variant="body2" color="textSecondary" mr={1}>Theme:</Typography>
                <Typography component="span" variant="body2" fontWeight={500}>{selectedOptions?.theme?.label || '--'}</Typography>
              </div>
              <div>
                <Typography component="span" variant="body2" color="textSecondary" mr={1}>Category:</Typography>
                <Typography component="span" variant="body2" fontWeight={500}>{selectedOptions?.category?.label || '--'}</Typography>
              </div>
              <div>
                <Typography component="span" variant="body2" color="textSecondary" mr={1}>Country:</Typography>
                <Typography component="span" variant="body2" fontWeight={500}>{selectedOptions?.countries?.at(0)?.label || '--'}</Typography>
              </div>
              <div>
                <Typography component="span" variant="body2" color="textSecondary" mr={1}>Year:</Typography>
                <Typography component="span" variant="body2" fontWeight={500}>{selectedOptions?.yearsRange?.startYear} - {selectedOptions?.yearsRange?.endYear}</Typography>
              </div>
            </Stack>

            <Box minHeight={600}>
              {!loading && !hasData && (
                <Stack sx={{ height: 600 }} alignItems="center" justifyContent="center" gap={1}>
                  <SvgIcon component={DataPlaceholderIcon} inheritViewBox sx={{ fontSize: 100 }}/>
                  <Typography textAlign="center" color="textSecondary" variant="body2">Please select variable from each of the following<br/>dimensions to view the report</Typography>
                </Stack>
              )}

              {loading && (
                <Stack sx={{ height: 600 }} alignItems="center" justifyContent="center" gap={1}>
                  <CircularProgress size={32} />
                  <Typography textAlign="center" color="textSecondary" variant="body2">Loading data...</Typography>
                </Stack>
              )}

              {currTab === 'table' && !loading && hasData && (
                <TableContainer>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        {yearsList.map((y, i) => (
                          <TableCell key={`${i}-${y}`} align="center">{y}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody sx={{ border: '1px solid #E4E7EC' }}>
                      {parsedData.map((d, i) => (
                        <TableRow key={`${i}-${d.label}`} hover>
                          <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 300 }}>{d.label}</TableCell>
                          {yearsList.map((y, j) => (
                            <TableCell key={`${i}-${j}-${y}`} align="center">{d.data[y] || '--'}<small>{d.data[y] && d.type === 'percentage' && '%'}</small></TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {currTab === 'chart' && !loading && hasData && (
                <Box mt={2}>
                  {chartOptions && chartOptions.series.length > 0 ? (
                    <HighChartsReact
                      highcharts={HighCharts}
                      options={chartOptions}
                      containerProps={{
                        style: {height: 500}
                      }}
                    />
                  ):(
                    <Typography>No data to plot.</Typography>
                  )}
                </Box>
              )}
            </Box>
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
};

export default Indicators;
