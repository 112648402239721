
import type { FC } from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { Controller } from 'react-hook-form';
import { MdAdd, MdOutlineRemove } from "react-icons/md";
import PositionsDropdown from '../PositionsDropdown';
import ThemesDropdown from '../ThemesDropdown';
import CountriesDropdown from '../CountriesDropdown';

import { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';

export type CareerPositionsFormProps = {
  name: string;
  disabled?: boolean;
};

const CareerPositionsForm:FC<CareerPositionsFormProps> = (props) => {
  const {
    name,
    disabled
  } = props;

  const {fields, append, remove} = useFieldArray({
    name
  });

  const handleAddPosition = useCallback(() => {
    append({
      positionId: '',
      themeId: '',
      countryId: '',
      positionDate: ''
    });
  }, [append]);

  return (
    <Stack gap={2}>
      {fields.map((data, i) => (
        <Stack key={data.id} direction={{ md: 'row' }} gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.positionId`}
                render={({field, fieldState: { error }}) => (
                  <FormControl required disabled={disabled} error={Boolean(error)} fullWidth>
                    <FormLabel>Position</FormLabel>
                    <PositionsDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.themeId`}
                render={({field, fieldState: { error }}) => (
                  <FormControl required disabled={disabled} error={Boolean(error)} fullWidth>
                    <FormLabel>Sector</FormLabel>
                    <ThemesDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.countryId`}
                render={({field, fieldState: { error }}) => (
                  <FormControl required disabled={disabled} error={Boolean(error)} fullWidth>
                    <FormLabel>Country</FormLabel>
                    <CountriesDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller 
                name={`${name}.${i}.positionDate`}
                render={({field, fieldState: { error }}) => (
                  <FormControl required disabled={disabled} error={Boolean(error)} fullWidth>
                    <FormLabel>Date</FormLabel>
                    <OutlinedInput
                      placeholder="Select date"
                      {...field}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
    
          <div style={{ flexShrink: 0 }}>
            <FormLabel>&nbsp;</FormLabel>
            <Button fullWidth
              variant="outlined"
              color="error"
              startIcon={<MdOutlineRemove size={24} />}
              disabled={disabled}
              onClick={() => remove(i)}
              size="large"
            >Remove</Button>
          </div>
        </Stack>
      ))}

      <div>
        <Button
          color="success"
          variant="outlined"
          startIcon={<MdAdd size={24}/>}
          disabled={disabled}
          onClick={handleAddPosition}
          size="large"
        >Add position</Button>
      </div>
    </Stack>
  );
};

export default CareerPositionsForm;
